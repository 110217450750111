<template>
  <div class="dynamic clearfix">
    <heand :tagnum="tagnum" />
    <div class="dynamic-con">
      <navleft v-on:cateid="getcateid" :tagnumx="tagnumx" />
      <div class="dynamicright">
        <div class="dynamicbox">
          <div class="casinput">
            <span>所在地区：</span>
            <!-- <el-cascader
              :options="options"
              v-model="area"
              clearable
              :props="{ checkStrictly: true,expandTrigger: 'hover' }"
              @change="optionschange($event,5)"
            ></el-cascader>-->
            <Cascader
              :data="options"
              v-model="area"
              change-on-select
              @on-change="optionschange($event, 5)"
            ></Cascader>
          </div>
          <div @click="pupshow = true" v-if="secondshow">
            <span>选择产业：</span>
            <el-input
              v-model="pupvalue"
              placeholder="请选择"
              readonly
            ></el-input>
          </div>
          <!-- <el-button @click="querycondition()">搜索</el-button> -->
        </div>
        <!-- <h2>行业简介</h2>
        <p class="details">{{alldata.info}}</p> -->
        <h2>企业速览</h2>
        <div class="speedAt">
          <span class="pg" @click="$router.push({ name: 'merchantsradar' })"
            >产业智能评估</span
          >
          <div
            :class="alldata.nums_all ? 'speedAtBox pointers' : 'speedAtBox'"
            data-select="7"
            @click="alldata.nums_all ? lookcomp($event) : ''"
          >
            <div>
              <p>企业总数</p>
              <h3>
                <span>{{ alldata.nums_all }}</span
                >户
              </h3>
            </div>
            <img class="qiye" src="@/assets/merchantsradar/qiye.png" alt />
          </div>
          <div class="speedAtBox">
            <div>
              <p>注册资本</p>
              <h3>
                <span>{{ alldata.nums_capital }}</span
                >亿
              </h3>
            </div>
            <img class="qiye" src="@/assets/merchantsradar/ziben.png" alt />
          </div>
          <div class="speedAtBox">
            <div>
              <p>就业人数</p>
              <h3>
                <span>{{ alldata.nums_bao }}</span
                >人
              </h3>
            </div>
            <img class="qiye" src="@/assets/merchantsradar/renshu.png" alt />
          </div>
          <div class="speedAtBox">
            <div>
              <p>授权发明专利</p>
              <h3>
                <span>{{ alldata.nums_patent }}</span
                >件
              </h3>
            </div>
            <img class="qiye" src="@/assets/merchantsradar/zhuanli.png" alt />
          </div>
          <div
            :class="alldata.nums_dengl ? 'speedAtBox pointers' : 'speedAtBox'"
            data-select="1"
            @click="alldata.nums_dengl ? lookcomp($event) : ''"
          >
            <div>
              <p>瞪羚企业</p>
              <h3>
                <span>{{ alldata.nums_dengl }}</span
                >户
              </h3>
            </div>
            <img class="qiye" src="@/assets/merchantsradar/qiye2.png" alt />
          </div>
          <div
            :class="alldata.nums_hignew ? 'speedAtBox pointers' : 'speedAtBox'"
            data-select="2"
            @click="alldata.nums_hignew ? lookcomp($event) : ''"
          >
            <div>
              <p>高新企业</p>
              <h3>
                <span>{{ alldata.nums_hignew }}</span
                >户
              </h3>
            </div>
            <img class="qiye" src="@/assets/merchantsradar/xinxing.png" alt />
          </div>
          <div
            :class="alldata.nums_tbe ? 'speedAtBox pointers' : 'speedAtBox'"
            data-select="3"
            @click="alldata.nums_tbe ? lookcomp($event) : ''"
          >
            <div>
              <p>科技型中小企业</p>
              <h3>
                <span>{{ alldata.nums_tbe }}</span
                >户
              </h3>
            </div>
            <img class="qiye" src="@/assets/merchantsradar/keji.png" alt />
          </div>
          <div
            :class="alldata.nums_quoted ? 'speedAtBox pointers' : 'speedAtBox'"
            data-select="4"
            @click="alldata.nums_quoted ? lookcomp($event) : ''"
          >
            <div>
              <p>上市企业</p>
              <h3>
                <span>{{ alldata.nums_quoted }}</span
                >户
              </h3>
            </div>
            <img class="qiye" src="@/assets/merchantsradar/shangshi.png" alt />
          </div>
          <div
            :class="alldata.nums_financ ? 'speedAtBox pointers' : 'speedAtBox'"
            data-select="5"
            @click="alldata.nums_financ ? lookcomp($event) : ''"
          >
            <div>
              <p>融资企业</p>
              <h3>
                <span>{{ alldata.nums_financ }}</span
                >户
              </h3>
            </div>
            <img class="qiye" src="@/assets/merchantsradar/rongzi.png" alt />
          </div>
          <div
            :class="alldata.nums_unicorn ? 'speedAtBox pointers' : 'speedAtBox'"
            data-select="6"
            @click="alldata.nums_unicorn ? lookcomp($event) : ''"
          >
            <div>
              <p>独角兽企业</p>
              <h3>
                <span>{{ alldata.nums_unicorn }}</span
                >户
              </h3>
            </div>
            <img class="qiye" src="@/assets/merchantsradar/dujiao.png" alt />
          </div>
        </div>
        <h2>趋势预判</h2>
        <div class="broken">
          <div id="prognosis"></div>
          <div class="annual">
            <div>
              <span>企业个数：</span>
              <el-select
                v-model="valueone"
                placeholder="请选择"
                @change="changenum($event, 1)"
              >
                <el-option
                  v-for="item in optionssone"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div>
              <span>年度：</span>
              <el-select
                v-model="valuetwo"
                placeholder="请选择"
                @change="changenum($event, 2)"
              >
                <el-option
                  v-for="item in optionsstwo"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div>
              <span>新增量：</span>
              <el-select
                v-model="valuethr"
                placeholder="请选择"
                @change="changenum($event, 3)"
              >
                <el-option
                  v-for="item in optionssthr"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </div>
        </div>
        <h2>结构透视</h2>
        <div class="sixradar clearfix">
          <div class="sixradar-left">
            <div class="sixbox">
              <div id="one"></div>
            </div>
            <div class="sixbox">
              <div id="two"></div>
            </div>
            <div class="sixbox">
              <div id="three"></div>
            </div>
            <div class="sixbox">
              <div id="four"></div>
            </div>
            <div class="sixbox">
              <div id="five"></div>
            </div>
            <div class="sixbox">
              <div id="six"></div>
            </div>
          </div>
          <div class="sixradar-right">
            <div class="sixradar-right-top">
              <div class="firm">
                <div
                  :class="firmnum == 1 ? 'active' : ''"
                  @click="(firmnum = 1), (select = 1), gettop10()"
                >
                  上市企业
                </div>
                <div
                  :class="firmnum == 2 ? 'active' : ''"
                  @click="(firmnum = 2), (select = 2), gettop10()"
                >
                  专利数量
                </div>
                <div
                  :class="firmnum == 3 ? 'active' : ''"
                  @click="(firmnum = 3), (select = 3), gettop10()"
                >
                  500强
                </div>
              </div>
              <span>TOP10</span>
            </div>
            <div class="sixradar-right-bottom">
              <div class="ul">
                <div class="li" v-for="(item, index) in topdata" :key="index">
                  <div class="li-pro">
                    <div class="libot">
                      <div class="litop" :style="{ width: item.h_sum }"></div>
                    </div>
                    <span>{{ item.sum_money }}</span>
                  </div>
                  <div class="liword">
                    <span>{{ item.company_name }}</span>
                    <!-- <small>万元</small> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pup" v-if="pupshow">
      <div class="pup-box">
        <ul class="lian_tu_top">
          <li style="width: 33%">
            <img src="../../assets/industr/arrow-left.png" alt />
            上游
            <img src="../../assets/industr/arrow-right.png" alt />
          </li>
          <li style="width: 33%">
            <img src="../../assets/industr/arrow-left.png" alt />
            中游
            <img src="../../assets/industr/arrow-right.png" alt />
          </li>
          <li style="width: 33%">
            <img src="../../assets/industr/arrow-left.png" alt />
            下游
            <img src="../../assets/industr/arrow-right.png" alt />
          </li>
        </ul>
        <div class="clearfix">
          <el-collapse accordion>
            <el-collapse-item
              v-for="(item, index) in upstream.subNodeList"
              :key="index"
            >
              <template slot="title">
                <div class="title" @click="getproductFather(item.node)">
                  {{ item.node }}
                </div>
              </template>
              <ul>
                <li
                  v-for="(itemx, indexx) in item.subNodeList"
                  :key="indexx"
                  @click="getproduct(itemx.node)"
                >
                  {{ itemx.node }}
                  <!-- ({{ itemx.count }}) -->
                  <i
                    class="el-icon-arrow-right"
                    v-if="itemx.subNodeList.length > 0"
                    :class="subNodeListshow == itemx.node ? 'isactive' : ''"
                  ></i>
                  <ul
                    v-if="
                      itemx.subNodeList.length > 0 &&
                        subNodeListshow == itemx.node
                    "
                  >
                    <li
                      v-for="(items, indess) in itemx.subNodeList"
                      :key="indess"
                      @click.stop="getproductx(items.node)"
                    >
                      {{ items.node }}
                      <!-- ({{ items.count }}) -->
                    </li>
                  </ul>
                </li>
              </ul>
            </el-collapse-item>
          </el-collapse>
          <el-collapse accordion>
            <el-collapse-item
              v-for="(item, index) in midstream.subNodeList"
              :key="index"
            >
              <template slot="title">
                <div class="title" @click="getproductFather(item.node)">
                  {{ item.node }}
                </div>
                <!-- ({{ item.count }}) -->
              </template>
              <ul>
                <li
                  v-for="(itemx, indexx) in item.subNodeList"
                  :key="indexx"
                  @click="getproduct(itemx.node)"
                >
                  {{ itemx.node }}
                  <!-- ({{ itemx.count }}) -->
                  <i
                    class="el-icon-arrow-right"
                    v-if="itemx.subNodeList.length > 0"
                    :class="subNodeListshow == itemx.node ? 'isactive' : ''"
                  ></i>
                  <ul
                    v-if="
                      itemx.subNodeList.length > 0 &&
                        subNodeListshow == itemx.node
                    "
                  >
                    <li
                      v-for="(items, indess) in itemx.subNodeList"
                      :key="indess"
                      @click.stop="getproductx(items.node)"
                    >
                      {{ items.node }}
                      <!-- ({{ items.count }}) -->
                    </li>
                  </ul>
                </li>
              </ul>
            </el-collapse-item>
          </el-collapse>
          <el-collapse accordion>
            <el-collapse-item
              v-for="(item, index) in downstream.subNodeList"
              :key="index"
            >
              <template slot="title">
                <div class="title" @click="getproductFather(item.node)">
                  {{ item.node }}
                </div>
              </template>
              <ul>
                <li
                  v-for="(itemx, indexx) in item.subNodeList"
                  :key="indexx"
                  @click="getproduct(itemx.node)"
                >
                  {{ itemx.node }}
                  <!-- ({{ itemx.count }}) -->
                  <i
                    class="el-icon-arrow-right"
                    v-if="itemx.subNodeList.length > 0"
                    :class="subNodeListshow == itemx.node ? 'isactive' : ''"
                  ></i>
                  <ul
                    v-if="
                      itemx.subNodeList.length > 0 &&
                        subNodeListshow == itemx.node
                    "
                  >
                    <li
                      v-for="(items, indess) in itemx.subNodeList"
                      :key="indess"
                      @click.stop="getproductx(items.node)"
                    >
                      {{ items.node }}
                      <!-- ({{ items.count }}) -->
                    </li>
                  </ul>
                </li>
              </ul>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div>
          <el-button @click="pupshow = false">关闭</el-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  chaingraph,
  getRegion,
  radarsearch,
  radartendency,
  radardistribute,
  top10,
  enterList,
} from "@/api/index";
export default {
  name: "dynamic",
  data() {
    return {
      tagnum: 2,
      tagnumx: 2,
      firmnum: 1,
      input: "",
      options: [],
      valueone: "企业个数",
      valuetwo: "年度",
      valuethr: "新增量",
      one: 1,
      two: 1,
      thr: 1,
      backgroundColor: [],
      oneData: [],
      twoData: [],
      threeData: [],
      fourData: [],
      fiveData: [],
      sixData: [],
      area: ["", "", ""],
      upstream: [],
      midstream: [],
      downstream: [],
      pupvalue: "请选择",
      pupshow: false,
      secondshow: true,
      inid: "",
      optionsvalue2: "",
      product: "",
      alldata: "",
      optionssone: [
        { label: "企业个数", value: 1 },
        { label: "注册资本", value: 2 },
      ],
      optionsstwo: [
        { label: "年度", value: 1 },
        { label: "季度", value: 2 },
        { label: "月度", value: 3 },
      ],
      optionssthr: [
        { label: "新增量", value: 1 },
        { label: "累积量", value: 2 },
      ],
      yeardata: [],
      numdata: [],
      topdata: [],
      select: 1,
      ifbutton: true,
      subNodeListshow: false,
      rotate: 40,
      chartsPrognosis: null,
      charts1: null,
      charts2: null,
      charts3: null,
      charts4: null,
      charts5: null,
      charts6: null,
    };
  },
  created() {
    this.inid = localStorage.getItem("inidchild");
    this.getRegion();
  },

  mounted() {
    // 初始化折线图
    this.initPrognosis();
    // 初始化六个饼图
    this.initradarmap(1);
    this.initradarmap(2);
    this.initradarmap(3);
    this.initradarmap(4);
    this.initradarmap(5);
    this.initradarmap(6);
    //
    this.querycondition();
    this.getchaingraph();
  },
  methods: {
    // 点击前往企业列表页
    async lookcomp(e) {
      // console.log(e.currentTarget.dataset.select, this.inid, this.area);
      this.$router.push({
        // name: "chdetsils9",
        path: "/ldcomp/ldcomp",
        query: {
          select: e.currentTarget.dataset.select,
          product: this.product,
          inid: this.inid,
          area: this.area,
        },
      });
    },
    // 左侧导航条变化
    getcateid(data) {
      this.cate = data.cate;
      this.inid = data.id;
      if (this.cate != 1) {
        this.product = "";
        this.querycondition();
        this.secondshow = true;
        this.getchaingraph();
      } else {
        this.secondshow = false;
      }
      if (this.inid != 8) {
        this.tagnumx = -1;
      }
    },
    //获取链图数据
    async getchaingraph() {
      if (this.area == []) {
        this.area = ["", "", ""];
      }
      const res = await chaingraph({
        inid: this.inid,
        area: this.area,
      });
      this.upstream = res.nodeList[0];
      this.midstream = res.nodeList[1];
      this.downstream = res.nodeList[2];
    },
    // 获取省市区
    async getRegion() {
      const res = await getRegion();
      this.options = res;
    },
    // 下拉框匹配数据
    optionschange(e, data, num) {
      if (e[0] != undefined) {
        this.optionsvalue2 = e[0] + "/" + e[1] + "/" + e[2];
      } else {
        this.optionsvalue2 = "";
      }
      if (e.length == 1) {
        e.push("");
        e.push("");
      } else if (e.length == 2) {
        e.push("");
      }
      this.area = e;
      if (this.area.length == 0) {
        this.area = ["", "", ""];
      }
      this.querycondition();
    },
    // 折线图筛选项
    changenum(e, data) {
      switch (data) {
        case 1:
          this.one = e;
          break;
        case 2:
          this.two = e;
          break;
        case 3:
          this.thr = e;
          break;
      }
      this.getradartendency();
    },
    // 获取各企业资质下的企业数据
    async getradarsearch() {
      const res = await radarsearch({
        inid: this.inid, //行业id
        area: this.area, //[省， 市， 区]
        product: this.product, // 企业产品（ 链图）
      });
      this.alldata = res;
    },
    // 获取折线图数据
    async getradartendency() {
      if (!this.ifbutton) {
        this.ifbutton = false;
        return false;
      }
      this.yeardata = [];
      this.numdata = [];
      const res = await radartendency({
        inid: this.inid,
        area: this.area,
        sone: this.one,
        stwo: this.two,
        sthr: this.thr,
      });
      this.numdata = [];
      this.yeardata = [];
      for (var i = 0; i < res.length; i++) {
        this.yeardata.push(res[i].lable);
        this.numdata.push(res[i].value);
      }
      this.ifbutton = false;
      if (res.length != 0) {
        this.ifbutton = true;
      }

      this.prognosis();
    },
    // 获取前十企业
    async gettop10(num) {
      const res = await top10({
        inid: this.inid, //行业id
        area: this.area, //[省， 市， 区]
        product: this.product, // 企业产品（ 链图）
        select: this.select,
      });
      this.topdata = res;
    },
    // 点击链图列表查询数据
    getproductFather(name) {
      this.product = name;
      this.querycondition();
    },
    getproduct(name) {
      this.product = name;
      if (this.subNodeListshow == name) {
        this.subNodeListshow = false;
      } else {
        this.subNodeListshow = name;
      }
      this.querycondition();
    },
    getproductx(name) {
      this.product = name;
      this.querycondition();
    },
    // 获取饼图数据
    async getradardistribute() {
      const res = await radardistribute({
        inid: this.inid, //行业id
        area: this.area, //[省， 市， 区]
        product: this.product, // 企业产品（ 链图）
      });
      this.oneData = JSON.parse(JSON.stringify(res.entype));
      this.twoData = JSON.parse(JSON.stringify(res.currency));
      this.thrData = JSON.parse(JSON.stringify(res.time_distribute));
      this.fourData = JSON.parse(JSON.stringify(res.capital));
      this.fiveData = JSON.parse(JSON.stringify(res.rounds));
      this.sixData = JSON.parse(JSON.stringify(res.sector));
      this.radarmap(1, this.oneData);
      this.radarmap(2, this.twoData);
      this.radarmap(3, this.thrData);
      this.radarmap(4, this.fourData);
      this.radarmap(5, this.fiveData);
      this.radarmap(6, this.sixData);
    },
    // 切换行业 产业
    querycondition() {
      this.getradarsearch();
      this.getradartendency();
      this.getradardistribute();
      this.gettop10();
      this.getchaingraph();
    },
    // 初始化折线图
    initPrognosis() {
      this.chartsPrognosis = this.$echarts.init(
        document.getElementById("prognosis")
      );
      let option = {
        xAxis: {
          type: "category",
          axisTick: {
            show: false,
          },
          axisLabel: {
            interval: 0, //横轴信息全部显示
          },
          boundaryGap: false,
          min: 0,
          data: this.yeardata,
        },
        yAxis: {
          type: "value",
          // max: 40000,
          min: 0,
          name: "企业数量统计",
          axisLine: {
            show: false,
          },
          splitNumber: 8,
          axisTick: {
            show: false,
          },
          itemStyle: {},
        },
        series: [
          {
            type: "line",
            smooth: true,
            showSymbol: false,
            itemStyle: {
              normal: {
                color: "#00FF00",
                lineStyle: {
                  color: "#FE6A00",
                },
              },
            },
          },
        ],
      };
      this.chartsPrognosis.setOption(option);
    },
    // 更新折线图数据
    prognosis() {
      if (this.two == 1) {
        this.rotate = 40;
      } else {
        this.rotate = 4;
      }
      let option = {
        xAxis: {
          axisLabel: {
            rotate: this.rotate,
          },
        },
        series: [
          {
            data: this.numdata,
          },
        ],
      };
      this.chartsPrognosis.setOption(option);
    },
    // 初始化环状图
    initradarmap(num) {
      if (num == 1) {
        this.charts1 = this.$echarts.init(document.getElementById("one"));
        var text = "企业类型";
      } else if (num == 2) {
        this.charts2 = this.$echarts.init(document.getElementById("two"));
        var text = "企业注册币种";
      } else if (num == 3) {
        this.charts3 = this.$echarts.init(document.getElementById("three"));
        var text = "成立时间分布";
      } else if (num == 4) {
        this.charts4 = this.$echarts.init(document.getElementById("four"));
        var text = "注册资本分布";
      } else if (num == 5) {
        this.charts5 = this.$echarts.init(document.getElementById("five"));
        var text = "上市板块\n结构统计";
      } else if (num == 6) {
        this.charts6 = this.$echarts.init(document.getElementById("six"));
        var text = "融资轮次分布";
      }

      var colorList = [
        "#72DCFE",
        "#0191FE",
        "#FCD469",
        "#FCB269",
        "#FC8569",
        "#FE6A00",
        "#58D5FF",
        "#9E87FF",
        "#3DDC8C",
      ];
      let option = {
        title: {
          text: text,
          x: "25%",
          y: "43%",
          textStyle: {
            fontSize: 12,
            color: "rgba(51, 51, 51, 1)",
            fontWeight: "400",
          },
          subtextStyle: {
            align: "center",
          },
        },
        legend: {
          left: 240,
          // top: '50%',
          y: "center",
          // bottom: 20,
          itemGap: 10,
          itemWidth: 8,
          itemHeight: 8,
          icon: "rect",
          orient: "vertical",
          textStyle: {
            lineHeight: 14,
          },
          formatter: function(params) {
            let tip1 = "";
            let tip = "";
            let le = params.length; //图例文本的长度

            if (le > 12) {
              // console.log(le, "le");
              // console.log(params, "params");
              //几个字换行大于几就可以了
              let l = Math.ceil(le / 7); //有些不能整除，会有余数，向上取整
              for (let i = 1; i <= l; i++) {
                //循环
                if (i < l) {
                  //最后一段字符不能有\n
                  tip1 += params.slice(i * 7 - 7, i * 7) + "\n"; //字符串拼接
                  // console.log(tip1, "tip");
                } else if (i === l) {
                  //最后一段字符不一定够9个
                  tip = tip1 + params.slice((l - 1) * 7, le); //最后的拼接在最后
                  // console.log(params.slice((l - 1) * 7, le), "tip");
                }
              }
              return tip;
            } else {
              tip = params; //前面定义了tip为空，这里要重新赋值，不然会替换为空
              return tip;
            }
          },
        },
        tooltip: {
          trigger: "item",
        },
        series: [
          {
            type: "pie",
            center: ["35%", "45%"],
            radius: ["40%", "80%"],
            clockwise: true,
            avoidLabelOverlap: true,
            hoverOffset: 15,
            itemStyle: {
              normal: {
                color: function(params) {
                  return colorList[params.dataIndex];
                },
              },
            },
            label: {
              show: true,
              position: "inside",
              formatter: "{d}%",
            },
            labelLine: {
              normal: {
                length: 20,
                length2: 30,
                lineStyle: {
                  width: 1,
                },
              },
            },
            itemStyle: {
              normal: {
                color: function(params) {
                  //自定义颜色
                  var colorList = [
                    "#72DCFE",
                    "#0191FE",
                    "#FCD469",
                    "#FCB269",
                    "#FC8569",
                    "#FE6A00",
                    "#58D5FF",
                    "#9E87FF",
                    "#3DDC8C",
                  ];
                  return colorList[params.dataIndex];
                },
              },
            },
          },
        ],
      };
      if (num == 1) {
        this.charts1.setOption(option);
      } else if (num == 2) {
        this.charts2.setOption(option);
      } else if (num == 3) {
        this.charts3.setOption(option);
      } else if (num == 4) {
        this.charts4.setOption(option);
      } else if (num == 5) {
        this.charts5.setOption(option);
      } else if (num == 6) {
        this.charts6.setOption(option);
      }
    },
    // 环状图
    radarmap(num, data) {
      let option = {
        series: [
          {
            data: data,
          },
        ],
      };
      switch (num) {
        case 1:
          this.charts1.setOption(option);
          break;
        case 2:
          this.charts2.setOption(option);
          break;
        case 3:
          this.charts3.setOption(option);
          break;
        case 4:
          this.charts4.setOption(option);
          break;
        case 5:
          this.charts5.setOption(option);
          break;
        case 6:
          this.charts6.setOption(option);
          break;
        default:
          return;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.pointers {
  cursor: pointer;
}
.dynamic {
  background-color: #f2f4f7;
  padding-bottom: 34px;
  .casinput {
    margin-right: 20px;
  }
  .dynamic-con {
    padding: 0 30px;
    padding-top: 13px;
  }
  .dynamicright {
    padding: 40px 20px;
    float: right;
    width: calc(100% - 266px);
    background-color: #fff;
    min-height: 1000px;
    // padding-top: 40px;
  }
  .dynamicbox {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    display: flex;
    /deep/.el-select {
      width: 164px;
      height: 30px;
      input {
        width: 164px;
        height: 30px;
        background: #ffffff;
        border: 1px solid #dddddd;
      }
      /deep/.el-input__icon {
        line-height: 30px !important;
      }
    }
    /deep/.el-input {
      width: 146px;
      height: 30px;
      // margin-right: 30px;
      input {
        width: 146px;
        height: 30px;
        background: #ffffff;
        border: 1px solid #dddddd;
        cursor: pointer;
      }
      /deep/.el-icon-circle-close {
        background: url("../../assets/merchantsradar/cuo.png") 50% 50% no-repeat;
      }
      .el-icon-circle-close:before {
        content: "";
      }
    }
    /deep/.el-button {
      margin-left: 18px;
      width: 88px;
      height: 30px;
      background: #fe6a00;
      border-radius: 4px;
      line-height: 30px;
      padding: 0;
      span {
        padding: 0;
        font-size: 14px;
        font-weight: 400;
        color: #ffffff;
      }
    }
  }
  h2 {
    margin-top: 28px;
    font-size: 16px;
    font-weight: bold;
    color: #fe6a00;
    text-align: left;
    display: flex;
    align-items: center;

    &::before {
      content: "";
      display: block;
      width: 2px;
      height: 14px;
      background: #fe6a00;
      margin-right: 5px;
    }
  }
  .details {
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    margin-top: 18px;
    margin-left: 15px;
  }
  .speedAt {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    .pg {
      position: absolute;
      top: -88px;
      right: 152px;
      display: inline-block;
      text-align: center;
      line-height: 40px;
      min-width: 100px;
      height: 40px;
      padding: 0 15px;
      background: #fe6a00;
      border-radius: 4px;
      font-size: 18px;
      font-weight: 400;
      color: #ffffff;
      cursor: pointer;
    }
  }
  .speedAtBox {
    width: 255px;
    height: 116px;
    border: 1px solid #dddddd;
    border-radius: 2px;
    margin-top: 27px;
    margin-left: 22px;
    padding-left: 23px;
    padding-top: 20px;
    position: relative;

    div {
      float: left;
      text-align: left;
      p {
        font-size: 12px;
        font-weight: 400;
        color: #333333;
        margin-top: 7px;
      }
      h3 {
        font-size: 16px;
        font-weight: 400;
        color: #333333;
        margin-top: 20px;
        span {
          font-size: 30px;
          font-weight: bold;
          color: #333333;
          margin-right: 3px;
        }
      }
    }
    .qiye {
      position: absolute;
      top: 24px;
      right: 24px;
    }
  }
  #prognosis {
    width: 100%;
    height: 400px;
  }
  .broken {
    position: relative;
    .annual {
      position: absolute;
      right: 0;
      top: 0;
      display: flex;
      align-items: center;
      div {
        margin-right: 10px;
        span {
          font-size: 14px;
          font-weight: 400;
          color: #333333;
        }
      }
      /deep/.el-select {
        width: 164px;
        height: 30px;
        input {
          width: 164px;
          height: 30px;
          background: #ffffff;
          border: 1px solid #dddddd;
        }
        /deep/.el-input__icon {
          line-height: 30px !important;
        }
      }
      /deep/.el-input {
        width: 146px;
        height: 30px;
        input {
          width: 146px;
          height: 30px;
          background: #ffffff;
          border: 1px solid #dddddd;
        }
        /deep/.el-icon-circle-close {
          background: url("../../assets/merchantsradar/cuo.png") 50% 50%
            no-repeat;
        }
        .el-icon-circle-close:before {
          content: "";
        }
      }
      /deep/.el-button {
        margin-left: 18px;
        width: 88px;
        height: 30px;
        background: #fe6a00;
        border-radius: 4px;
        line-height: 30px;
        padding: 0;
        span {
          padding: 0;
          font-size: 14px;
          font-weight: 400;
          color: #ffffff;
        }
      }
    }
  }
}
.sixradar {
  width: 100%;
  .sixradar-left {
    margin-top: 28px;
    width: calc(100% - 408px);
    float: left;
    display: flex;
    flex-wrap: wrap;
    .sixbox {
      margin-top: 40px;
      position: relative;
      flex: 1;
      #one,
      #two,
      #three,
      #four,
      #five,
      #six {
        width: 350px;
        height: 250px;
      }
      .one-orinet {
        position: absolute;
        right: 30px;
        top: 23px;
        z-index: 99999;
        ul {
          li {
            display: flex;
            align-items: center;
            font-size: 12px;
            font-weight: 400;
            color: #333333;
            line-height: 24px;
            list-style: none;
            i {
              display: inline-block;
              width: 7px;
              height: 7px;
              background: #72dcfe;
              margin-right: 5px;
            }
          }
        }
      }
    }
  }
  .sixradar-right {
    float: right;
    width: 408px;
    .sixradar-right-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .firm {
        display: flex;
        div {
          width: 91px;
          height: 30px;
          background: rgba(254, 106, 0, 0.1);
          border-radius: 4px;
          font-size: 14px;
          font-weight: 400;
          color: #fe6a00;
          margin-right: 10px;
          line-height: 30px;
          cursor: pointer;
          &.active {
            background-color: rgba(254, 106, 0, 1);
            color: #fff;
          }
        }
      }
    }
    .sixradar-right-bottom {
      margin-top: 10px;
      min-height: 463px;
      background: #f3f6fb;
      width: 368px;
      padding: 14px 20px;
      .ul {
        .li {
          .li-pro {
            display: flex;
            align-items: center;
            justify-content: space-between;
            .libot {
              width: 290px;
              height: 12px;
              background: #ffffff;
              box-shadow: 0px 0px 6px 0px rgba(151, 215, 175, 0.35);
              border-radius: 6px;
              .litop {
                width: 268px;
                height: 12px;
                background: linear-gradient(90deg, #22baf7, #37e0f7);
                box-shadow: 0px 3px 10px 0px rgba(35, 247, 188, 0.35);
                border-radius: 6px;
              }
            }
            span {
              font-size: 12px;
              font-weight: 400;
              color: #333333;
            }
          }
          .liword {
            text-align: left;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin: 8px 0;
            span {
              font-size: 14px;
              font-weight: 400;
              color: #333333;
            }
            small {
              font-size: 12px;
              font-weight: 400;
              color: #333333;
              padding-right: 18px;
            }
          }
        }
      }
    }
  }
}
.pup {
  .pup-box {
    position: fixed;
    width: 930px;
    min-height: 454px;
    padding-bottom: 30px;
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 4px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
    .lian_tu_top {
      overflow: hidden;
      zoom: 1;
      margin: 20px 0 20px 20px;
      li {
        padding: 0;
        overflow: hidden;
        zoom: 1;
        float: left;
        height: 63px;
        line-height: 63px;
        vertical-align: top;
        text-align: center;
        font-size: 17px;
        background: linear-gradient(90deg, #22baf7, #37e0f7);
        color: #fff;

        img {
          height: 63px;
          display: inline;
          float: left;
          vertical-align: top;
        }
        img:last-child {
          float: right;
        }
      }
    }
    .el-button {
      width: 88px;
      height: 31px;
      background: #ffffff;
      border: 1px solid #fe6a00;
      border-radius: 4px;
      margin-top: 10px;
      padding: 0;
      color: #fe6a00;
      &:nth-child(1) {
        width: 88px;
        height: 31px;
        background: #fe6a00;
        border-radius: 4px;
        font-size: 14px;
        font-weight: 400;
        color: #fff;
      }
    }
    //下拉部分
    /deep/.el-collapse {
      border: 0;
      background: #c7e3ff;
      padding: 3%;
      margin-left: 2%;
      width: 30%;
      float: left;
      height: 340px;
      overflow-y: auto;
    }

    /deep/.el-collapse:nth-of-type(2) {
      background: #dbedff;
    }

    /deep/.el-collapse:nth-of-type(3) {
      background: #eef7ff;
    }
    ::-webkit-scrollbar {
      width: 5px;
      background-color: #eee;
    }

    /*定义滚动条轨道 内阴影+圆角*/
    ::-webkit-scrollbar-track {
      border-radius: 7px;
      background-color: #eee;
    }

    ::-webkit-scrollbar-thumb {
      border-radius: 7px; /*滚动条的圆角*/
      background-color: #9dcaf7; /*滚动条的背景颜色*/
    }
    .title {
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    /deep/.el-collapse-item__header {
      font-size: 16px;
      text-align: left;
      vertical-align: middle;
      background: none;
      font-weight: bold;
      border-bottom: 0;
      // margin-bottom: 15px;
      // height: 36px;
      height: auto;
      // line-height: 36px;
      // line-height: 36px;
      margin-bottom: 15px;
      max-width: 200px;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
      img {
        margin-right: 6px;
      }
    }
    /deep/.el-collapse-item__wrap {
      background: none;
      text-align: left;
      border-bottom: 0;
      font-weight: 400;
      color: #555;
      padding-left: 30px;
    }
    /deep/.el-collapse-item__content {
      padding-bottom: 0;
      li {
        font-size: 14px;
        // margin-bottom: 15px;
        text-align: left;
        list-style: none;
        line-height: 30px;
        cursor: pointer;
        i {
          transition: all 0.25s;
          &::before {
            display: inline-block;
            content: "\e6e0";
            font-size: 16px;
            margin-bottom: 5px;
          }
        }
        ul {
          li {
            font-size: 14px;
            min-height: 24px;
            line-height: 24px;
            margin-left: 20px;
            margin-bottom: 20px;
          }
        }
      }
    }
  }
}
/deep/.ivu-cascader {
  height: 30px;
  line-height: 30px;
  width: 200px !important;
  display: inline-block;
  input {
    width: 200px !important;
    margin-right: 20px;
  }
}
</style>
